import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ImageGrid from '../ImageGrid/ImageGrid'

export default function Work (): JSX.Element {
  const [images, setImages] = useState<string[]>([])

  useEffect(() => {
    const getImages = async (): Promise<void> => {
      const data = await fetch('images.json')
      const images = await data.json() as string[]
      let imageNumbers: number[] = _.map(images, (image: string) => +image)
      imageNumbers = _.sortBy(imageNumbers)
      const imageStrings = _.map(imageNumbers, image => `images/${image}.jpg`)
      setImages(imageStrings)
    }
    getImages().catch(console.error)
  }, [])

  return (
    <div className='work-page'>
        <ImageGrid images={images} />
    </div>
  )
}
