import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Layout from './components/Layout/Layout'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Work from './components/work/Work'
import About from './components/About/About'
import Boudoir from './components/Boudoir/Boudoir'
import ScrollToTop from './scrollToTop'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Work />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<Work />} />
          <Route path="boudoir" element={<Boudoir />} />
          <Route path="*" element={<Work />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// tslint:disable-next-line:no-unsafe-any
void reportWebVitals()
