import React, { useState } from 'react'
import './Carousel.css'

interface Props {
  images: string[]
  selectedImage: number
  close: () => void
}

enum Direction {
  FORWARD,
  BACK
}

export default function Carousel ({ images, selectedImage, close }: Props): JSX.Element {
  const [imageIndex, setImageIndex] = useState<number>(selectedImage)

  const setItem = (selector: Direction): void => {
    const numberOfImages = images.length

    switch (selector) {
      case Direction.FORWARD:
        if (imageIndex < numberOfImages - 1) {
          setImageIndex(imageIndex + 1)
        }
        break

      case Direction.BACK:
        if (imageIndex > 0) {
          setImageIndex(imageIndex - 1)
        }
        break

      default:
    }
  }

  if (imageIndex === undefined) return <></>

  return (
    <div className='carousel'>
      <div className='image-container'>
        <div className='icon-holder close-button'>
          <i className='gg-close-o' onClick={close} aria-hidden="true"></i>
        </div>
        <div className='icon-holder-arrow'>
          <i className='gg-chevron-left' onClick={() => { setItem(Direction.BACK) }}></i>
        </div>
        <div className='image-wrapper'>
          <img className='image' src={images[imageIndex]} alt={images[imageIndex]} />
        </div>
        <div className='icon-holder-arrow'>
          <i className='gg-chevron-right' onClick={() => { setItem(Direction.FORWARD) }}></i>
        </div>
      </div>
    </div>
  )
}
