import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import './Layout.css'
import twitterLogo from '../../assets/icons/twitter.png'
import instagramLogo from '../../assets/icons/instagram.png'
import onlyfansLogo from '../../assets/icons/onlyfans.png'
import { GiHamburgerMenu } from 'react-icons/gi'

export default function Layout (): JSX.Element {
  const [menuDropDownOpen, setMenuDropDownOpen] = useState(false)

  return (
    <>
      <nav className="header">
        <ul>
          <li className="logo">Ifa Brand</li>
          <li>
            <Link to="/work">Work</Link>
          </li>
          <li>
            <Link to="/boudoir">Boudoir</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      </nav>
      <nav className="mobile-header">
        <div className="icon-holder">
          <GiHamburgerMenu
            onClick={() => {
              setMenuDropDownOpen(!menuDropDownOpen)
            }}
            aria-hidden="true"
          ></GiHamburgerMenu>
        </div>
        {menuDropDownOpen && (
          <div>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    setMenuDropDownOpen(false)
                  }}
                  to="/work"
                >
                  Work
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setMenuDropDownOpen(false)
                  }}
                  to="/boudoir"
                >
                  Boudoir
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setMenuDropDownOpen(false)
                  }}
                  to="/about"
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
      <div className="main-section">
        <nav className="link-icons">
          <a
            href="https://twitter.com/IfaBrand"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitterLogo} alt="twitter icon" />
          </a>
          <a
            href="https://www.instagram.com/ifabrand/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagramLogo} alt="instagram icon" />
          </a>
          <a
            href="https://www.onlyfans.com/ifabrand"
            target="_blank"
            rel="noreferrer"
          >
            <img src={onlyfansLogo} alt="onlyfans icon" />
          </a>
        </nav>
        <Outlet />
      </div>
    </>
  )
}
