import React from 'react'
import './Boudoir.css'

export default function Boudoir (): JSX.Element {
  return (
    <div className='boudoir-page'>
      <div>
        <strong>BOUDOIR</strong> is the first hardcover photobook by Belgian Playboy cover model turned self-portraitist Ifa Brand.
      </div>
      <div>
        <br/>Shop <a target='_blank' rel='noreferrer' href='https://linktr.ee/ifabrand'>HERE</a>
        <br/>1st edition numbered 500, with artist signature in every copy.
        <br/>Introduction: Nora Kobrenik
        <br/>Designer: Jean-Michel Dentand
        <br/>Publisher: Circa Press
        <br/>ISBN 978-1-911422-31-0
      </div>
      <div>
        <br/><br/><br/><strong>About the book: </strong><br/><br/>
        With BOUDOIR, Ifa Brand takes us to an erotic image world constructed entirely from a female perspective. Spanning the first four years of her creative journey as a photographer, BOUDOIR is the debut book by Belgian Playboy cover model turned self-portraitist Ifa Brand.
        <br/><br/>‘Photography allows me to be both director and diva, to explore my long love of vin-tage erotica, and to create my own instant film stills. It is a journey towards self-empowerment.’ – Ifa Brand
        <br/><br/>‘Ifa Brand’s message is simple: patriarchy profits from women’s sexuality, yet when a woman owns her sexuality she is immediately punished for it. (Case in point – constant censorship by social media platforms and art world superiors.) In a male-dominated world, female sexuality is only permissible for male pleasure and for male desires.
        <br/><br/>Brand’s work provides a feminist counterbalance by allowing her desires to become one with her sexual fantasies and inspirations, and above all to empower herself unapologetically. Some poses may seem submissive, but she is always the one in control, the one who sets the scene. The one who decides what’s erotic and what’s obscene, what’s allowed to be shown in public and what’s not. Think of Le Déjeuner sur l’herbe, by Édouard Manet: the woman is naked but stares confidently at the viewer.
        <br/><br/>The question remains the same – can you handle it?’ – Nora Kobrenik

        <br/><br/><br/><strong>About the writer:</strong>
        <br/><br/>Nora Kobrenik is Edi­tor-In-Chief and Founder of Tar­tarus Mag­a­zine, and a pho­tog­ra­ph­er, mod­el, styl­ist, design­er and artist. Born and raised in the for­mer USSR, she com­bines Russ­ian opu­lence, hard­ness, ele­gance and class with Amer­i­can tra­di­tions, inde­pen­dence and inno­va­tion. She is the patron saint of the scan­dalous, out­ra­geous and mon­strous, and Tar­tarus Mag­a­zine serves as her church and forum. She divides her time between Con­necti­cut and New York City.

        <br/><br/><br/><strong>About Circa Press:</strong>
        <br/><br/>Circa Press is a young, experimental press, rooted in a wealth of publishing  experience. We are working with authors, artists, designers and  photographers who are passionate about what they do. Often they are  pioneers – mavericks and risk takers – whose creative instincts can be  provocative. They encourage us to push boundaries and explore new  ground. We value these collaborations, and together we aim to reflect a  spirit of adventure in publications with the highest editorial and  production values. Our program embraces all aspects of ‘visual  culture’, which means anything aesthetically significant that intrigues  or excites us. Above all, we are motivated by powerful writing and compelling ideas. And we love making beautiful books!
      </div>
    </div>
  )
}
