import React from 'react'
import './About.css'

export default function About (): JSX.Element {
  return (
    <div className='about-page'>
      <div className='section'>
        <strong>IFA BRAND</strong> graduated from the Royal Academy of Fine Arts, Antwerp, as a master silversmith & jewelry designer. She began modeling as a hobby, but it soon assumed a bigger role in her life. In 2013, she was featured on the cover of Dutch Playboy magazine. She describes this period in her life:<br/><br/>
        ‘I modeled for a few years but never felt satisfied. The dissatisfaction stemmed from not being able to represent my true identity. I fell between styles: alternative but not alternative enough; fetish but not in a typical sense. I also disliked photographers telling me how I should look, pose, behave; telling me no to red lips, no to precious lingerie and all the things I loved.’<br/><br/>
        In 2017, she decided to go her own way. She saw photography as an opportunity to be her own creative director, and to explore her own vision of sensuality, eroticism and fetish. Hers is a quest for personal empowerment through the art of self-portraiture.
        She bought a Polaroid camera to experiment with and loved it immediately: ‘It gave me a whole new experience – the sound of the camera taking the image, the smell of the chemicals, which are so strong and specific to Polaroid, and the way the images roll out of the camera, unedited and pure. For me, technique is secondary to feeling. I think in colors, shadows and contrast. Polaroid is unstable, always unpredictable. It challenges you each time.’
        <br/><br/>She poses gracefully as the blonde bombshell in her own silent Polaroid film still between mirrored ceilings and floral wallpapers. High heels spike the pillows. Red lips, a pale complexion and platinum blonde hair in her own Femme Fatale construction. With her work she provides a gaze into an erotic image world constructed entirely from a female perspective. Her quest continues until this very day.
        <br/><br/>Her work has been published internationally in magazines such as Playboy NL, many Polaroid themed publications, national Belgian newspapers and more.
        <br/><br/>Her first coffee table photobook ‘Ifa Brand - BOUDOIR’, by Circa Press (UK) has been published late 2021 and is available for purchase worldwide in a limited edition of 500 copies. It is available in selected bookstores and online via Amazon.com, Waterstones.com, Barnes&Noble and many more.
      </div>
      <div className='section'>
        <strong>PUBLICATIONS</strong>
        <ul>
          <li>
            <div>Photodarium calendar - Private edition 2019, 2020, 2021, 2022, 2023.</div>
            <a target='_blank' rel='noreferrer' href='https://www.photodarium.de/'>link</a>
          </li>
          <li>
            <div>Phonographer Magazine – Issue 03/19</div>
            <a target='_blank' rel='noreferrer' href='https://phonographer.nl/'>link</a>
          </li>
          <li>
            <div>Sinical Magazine – Issue December 2019</div>
            <a target='_blank' rel='noreferrer' href='https://www.magcloud.com/browse/magazine/162779'>link</a>
          </li>
          <li>
            <div>Caro, Andrés Aguilar. 2020, 10 January. Ifa Brand. Guest photographers. Polaroid Originals. Instant Photographers.com</div>
            <a target='_blank' rel='noreferrer' href='http://instantphotographers.com/ifa-brand/'>link</a>
          </li>
          <li>
            <div>Le Bateau Magazine – Issue 17 – La Nuit</div>
            <a target='_blank' rel='noreferrer' href='https://polysememagazine.bigcartel.com/'>link</a>
          </li>
          <li>
            <div>Imagenation Paris Press - Instant Art Paris 2020 - photobook by Imagenation Paris Pres.</div>
          </li>
          <li>
            <div>Hindriks, Jeroen. Vlam Magazine NL - Edition 01/2021</div>
            <a target='_blank' rel='noreferrer' href='https://www.vlammagazine.nl/'>link</a>
          </li>
          <li>
            <div>Mitchell, Tony. 2021, 11 May. Ifa Brand Boudoir kickstarter.</div>
            <a target='_blank' rel='noreferrer' href='https://thefetishistas.com/ifa-brand-boudoir-kickstarter'>link</a>
          </li>
          <li>
            <div>Wyers, Marij. 2021, 22 May. Voormalig Playboy-model uit Borgloon brengt boek uit met erotische zelfportretten. Het Belang van Limburg.</div>
            <a target='_blank' rel='noreferrer' href='https://www.hbvl.be/cnt/dmf20210521_97985054'>link</a>
          </li>
          <li>
            <div>Provoost, Bert. 2021, 18 May. Heists Playboymodel fotografeert zichzelf met Polaroid: “Ik vind het heel leuk om een vrouw te zijn”. Gazet van Antwerpen. </div>
            <a target='_blank' rel='noreferrer' href='https://www.gva.be/cnt/dmf20210518_95292126'>link</a>
          </li>
          <li>
            <div>Wyers, Marij. 2021, 22 May - Voormalig Playboy-model plooit niet voor censuur: “Helemaal oké om jezelf te tonen als vrouw”. Het Nieuwsblad.</div>
            <a target='_blank' rel='noreferrer' href='https://www.nieuwsblad.be/cnt/dmf20210522_91715341'>link</a>
          </li>
          <li>
            <div>Provoost, Bert. 2021, 18 May. Heists Playboymodel fotografeert zichzelf met Polaroid: “Ik vind het heel leuk om een vrouw te zijn”. Het Nieuwsblad.</div>
            <a target='_blank' rel='noreferrer' href='https://www.nieuwsblad.be/cnt/dmf20210518_95292126'>link</a>
          </li>
          <li>
            <div>Playboy NL, JULY 2021, Page 18-19. ‘Polaroid my love.’</div>
            <a target='_blank' rel='noreferrer' href='https://playboy.nl/'>link</a>
          </li>
          <li>
            <div>Mitchell, Tony. 2021, Nov. The Fetishistas, cover story, Ifa Brand Boudoir Interview.</div>
            <a target='_blank' rel='noreferrer' href='https://thefetishistas.com/ifa-brand-boudoir-interview/'>link</a>
          </li>
          <li>
            <div>Nakid Magazine. Online feature: ’80’S RENEGADE’ A NEW VISUAL STORY BY PHOTOGRAPHER ‘IFA BRAND’.</div>
            <a target='_blank' rel='noreferrer' href='https://playboy.nl/'>link</a>
          </li>
          <li>
            <div>Moan Zine - Issue 4.</div>
            <a target='_blank' rel='noreferrer' href='https://www.instagram.com/moan_zine/'>link</a>
          </li>
          <li>
            <div>Indent Magazine - Issue 2 - Artist Feature. Page 46 - 54. </div>
          </li>
        </ul>
      </div>
      <div className='section'>
        <strong>UPCOMING EVENTS:</strong>
      </div>
      <div className='section'>
        <strong>SOLO SHOWS:</strong>
        <div>
          FATALE. Solo-expo @De Groene Hond Mechelen. Korenmarkt 7, 2800 Mechelen, Belgium.<br/>
          APRIL 22, 23 & 29, MAY 1 2023.
        </div>
      </div>
      <div className='section'>
        <strong>GROUP SHOWS:</strong>
        <div>
          2019: Capturing the Female Mind Through Art – Antwerp, BE.
        </div>
      </div>
    </div>
  )
}
