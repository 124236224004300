import React, { useState } from 'react'
import _ from 'lodash'
import './ImageGrid.css'
import Carousel from '../Carousel/Carousel'

interface Props { images: string[] }

export default function ImageGrid ({ images }: Props): JSX.Element {
  const [selectedImage, setSelectedImage] = useState<number>()

  const imageElements = _.map(images, (image: string, index: number) => {
    return <div key={image} className='image-container' onClick={() => { setSelectedImage(index) }}><img className='image-thumbnail' src={image} alt={image} /></div>
  })

  return (
    <>
      {(selectedImage !== undefined) && <Carousel images={images} selectedImage={selectedImage} close={() => { setSelectedImage(undefined) }} />}
      <div className='image-grid'>
        {imageElements}
      </div>
    </>
  )
}
